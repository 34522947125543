import React, { SFC } from 'react';
import Tilt from 'react-vanilla-tilt';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import AppLink from './AppLink';
import IconDesign from './icons/IconDesign';
import IconDev from './icons/IconDev';
import IconArchi from './icons/IconArchi';
import IconStrategy from './icons/IconStrategy';
import IconRecruitment from './icons/IconRecruitment';
import {
  color,
  radius,
  spacing,
  fontFamily,
  fontSize,
  mqUp,
} from '../../constants/styles';

interface ExpertiseCardProps {
  skill: {
    title: string,
    theme: 'design' | 'dev' | 'strategy' | 'transfer',
    url: string,
  }
}

const Card = styled.div`
  position: relative;
  width: 100%;
  max-width: 84rem;
  margin: 0 auto;
  background: ${color.light};
  border-radius: ${radius.XS};
  padding: ${spacing.L} ${spacing.M};

  ${mqUp(540)} {
    padding: ${spacing.XL} ${spacing.XXL};
  }

  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    top: ${spacing.M};
    left: calc(${spacing.M} * 2);
    right: 0;
    bottom: 0;
    background: ${color.light};
    box-shadow: 0 0 3rem ${color.neutralDark};
    opacity: 0.15;
  }
`;

const Title = styled.div`
  font-family: ${fontFamily.secondaryBold};
  font-size: ${fontSize.L};
  color: ${color.neutralDark};
  letter-spacing: 0.04em;
`;

const Tags = styled.div`
  font-family: ${fontFamily.secondaryMedium};
  font-size: ${fontSize.XS};
  margin-top: .2rem;
  li {
    display: inline-block;
    & + li {
      &:before {
        content: '•';
        display: inline-block;
        margin: 0 ${spacing.XS};
      }
    }
  }
`;

const Content = styled.div`
  color: ${color.neutral};
  margin-top: 2rem;
  p {
    & + p {
      margin-top: 2rem;
    }
  }
`;

const Icon = styled.div`
  text-align: center;
  margin-bottom: ${spacing.M};

  ${mqUp(540)} {
    position: absolute;
    z-index: 0;
    margin-right: 3rem;
    left: 0;
    top: ${spacing.XL};
    transform: translateX(-50%);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 2.1rem;
    background: currentColor;
  }
`;

export const Themes = {
  design: css`
    ${Tags} {
      color: ${color.clr1};
    }
    ${Icon} {
      color: ${color.clr1Light};
    }
  `,
  transfer: css`
    ${Tags} {
      color: ${color.clr2};
    }
    ${Icon} {
      color: ${color.clr2Light};
    }
  `,

  dev: css`
    ${Tags} {
      color: ${color.clr3};
    }
    ${Icon} {
      color: ${color.clr3Light};
    }
  `,
  archi: css`
    ${Tags} {
      color: ${color.clr4};
    }
    ${Icon} {
      color: ${color.clr4Light};
    }
  `,
  strategy: css`
    ${Tags} {
      color: ${color.clr5};
    }
    ${Icon} {
      color: ${color.clr5Light};
    }
  `,
};

const IconSkills = props => {
  switch (props.data) {
    case 'design':
      return <IconDesign />;
    case 'dev':
      return <IconDev />;
    case 'archi':
      return <IconArchi />;
    case 'strategy':
      return <IconStrategy />;
    case 'transfer':
      return <IconRecruitment />;
    default:
      return null;
  }
};

const SkillsCard: SFC<ExpertiseCardProps> = ({ skill, ...rest }) => (
  <Card
    key={skill.title}
    css={css`
      ${Themes[skill.theme]}
    `}
    {...rest}
  >
    <Icon>
      <IconSkills data={skill.theme} />
    </Icon>
    <Title>{skill.title}</Title>
    <Tags dangerouslySetInnerHTML={{ __html: skill.tags.html }} />
    <Content dangerouslySetInnerHTML={{ __html: skill.content.html }} />
  </Card>
);

SkillsCard.defaultProps = {};

export default SkillsCard;
