import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import App from '../components/layout/App';
import Wrapper from '../components/base/Wrapper';
import Hero from '../components/base/Hero';
import SkillsCard from '../components/base/SkillsCard';
import ExpertiseCard from '../components/base/ExpertiseCard';
import { spacing, mqDown } from '../constants/styles';
import expertiseIllustration from '../images/illustrations/expertise.svg';

const Container = styled.div`
  padding: .1rem 0 ${spacing.XXL} 0;
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.XL};
`;

const ExpertisePage = props => {
  const DATA = props.data.expertise.data;
  return (
    <App>
      <Hero
        titleLight={DATA.title_light}
        titleBold={DATA.title_bold}
        gradient={{start: '#72DBF6', end: '#5F54A2'}}
        illustration={expertiseIllustration}
      />
      <Container css={css`
        ${mqDown('tablet')} {
          display: none;
        }
      `}>
        <Wrapper
          css={css`
            margin-bottom: ${spacing.XXL};
          `}
        >
          <SkillsCard
            data={DATA.skills}
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper
          css={css`
            margin-bottom: ${spacing.XXL};
          `}
        >
          <Grid>
            {DATA.skills.map((skill, index) => (
              <ExpertiseCard
                key={skill.theme}
                skill={skill}
                id={skill.theme}
              />
            ))}
          </Grid>
        </Wrapper>
      </Container>
    </App>
  );
};

export default ExpertisePage;

export const expertiseQuery = graphql`
  query ExpertiseQuery {
    expertise: prismicSkills {
      data {
        title_light
        title_bold
        skills {
          theme
          title
          content {
            html
          }
          tags {
            html
          }
        }
      }
    }
  }
`;
